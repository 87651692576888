import React, { useState } from 'react'
import {graphql} from 'react-apollo'
import {gql} from 'graphql.macro'
import {skus} from 'utils/logistics'

import * as compose from 'lodash/flowRight'

import './index.scss'
import Loading from 'Components/Loading'
import { Input } from 'Components/Form'

const GET_DEVICE_IDS = gql`
  query GET_DEVICE_IDS {
    deviceIDs {
      deviceID
      model
      po
      sku
      hasTurnedOn
      simNumber
    }
  }
`

const UPDATE_SKU = gql`
  mutation UPDATE_SKU($id: Int, $sku: String) {
    updateSku(id: $id, sku: $sku) {
      devices {
        id
      }
    }
  }
`

const DeviceIDs = ({data: {loading, error, refetch, deviceIDs}, UpdateSku}) => {
  const [sortDirection, setSortDirection] = useState('desc')
  const [sortBy, setSortBy] = useState('id')

  if (error) return 'error'
  if (loading) return <Loading />

  function sort(key) { 
    setSortBy(key)
    setSortDirection(sortBy === key ? sortDirection === 'asc' ? 'desc' : 'asc' : 'asc')
  }

  function sortFunction() {
    const reverse = sortDirection === 'asc'
    switch (sortBy) {
      case 'id':
        return (a, b) => (reverse ? a.deviceID - b.deviceID : b.deviceID - a.deviceID)
      case 'model':
        return (a, b) =>
          (reverse ? 1 : -1) *
          (a.model || 'zzzzz').localeCompare(b.model || 'zzzzz')
      case 'po':
      return (a, b) => (reverse ? a.po - b.po : b.po - a.po)
      case 'sku':
        return (a, b) =>
          (reverse ? 1 : -1) *
          (a.sku || 'zzzzz').localeCompare(b.sku || 'zzzzz')
      case 'sim':
        return (a, b) => (reverse ? a.simNumber - b.simNumber : b.simNumber - a.simNumber)
      case 'has':
        return (a, b) => (reverse ? a.hasTurnedOn - b.hasTurnedOn : b.hasTurnedOn - a.hasTurnedOn)
      default:
        return undefined
    }
  }

  function sortIndicator(key) {
    return sortBy === key ? (
      sortDirection === 'asc' ? (
        <i className="fa fa-caret-up" />
      ) : (
        <i className="fa fa-caret-down" />
      )
    ) : null
  }
  const devices = (deviceIDs || []).sort(sortFunction())

  function handleUpdate(id, e) {
    UpdateSku({variables: {id: id, sku: e.target.value}})
    refetch()
  }
    return ( 
      <div className="view-device-ids">
        <table>
          <thead>
            <tr>
              <th
                className="sim-number clickable"
                onClick={()=> {sort('id')}}>
                  Device ID {sortIndicator('id')}
              </th>
              <th
                className="sim-number clickable"
                onClick={()=> {sort('model')}}
              >
                Model {sortIndicator('model')}
              </th>
              <th
                className="sim-number clickable"
                onClick={()=> {sort('po')}}>
                  P.O. {sortIndicator('po')}
              </th>
              <th 
                className="sim-number clickable"
                onClick={()=> {sort('sku')}}>
                  SKU {sortIndicator('sku')}
              </th>
              <th
                className="sim-number clickable"
                onClick={()=> {sort('sim')}}>
                  SIM Number {sortIndicator('sim')}
              </th>
              <th                 
                className="sim-number clickable"
                onClick={()=> {sort('has')}}>
                  Has Turned On {sortIndicator('has')}
              </th>
            </tr>
          </thead>
          <tbody>
            {devices.map(({deviceID, model, po, sku, simNumber, hasTurnedOn}) => (
              <tr
                key={deviceID}
                className={
                  hasTurnedOn ? 'has-turned-on' : 'has-not-turned-on'
                }
              >
                <td>{deviceID}</td>
                <td>{model || <em>None</em>}</td>
                <td>{po || <em>None</em>}</td>
                <td>{<Input
                  type="select"
                  value={sku}
                  options={skus}
                  onChange={e => handleUpdate(deviceID, e)}
                />}</td>
                <td>{simNumber || <em>None</em>}</td>
                <td>{hasTurnedOn ? 'Yes' : 'No'}</td>
              </tr>
            ))
            }
          </tbody>
        </table>
      </div>
    )
}

export default compose(
  graphql(GET_DEVICE_IDS),
  graphql(UPDATE_SKU, {name: 'UpdateSku'})
)(DeviceIDs)