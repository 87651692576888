import React, {Component} from 'react'
import {Input, InputRow} from 'Components/Form'
import update from 'immutability-helper'
import {manufacturers, skus} from 'utils/logistics'
import {gql} from 'graphql.macro'
import {graphql} from 'react-apollo'
import * as compose from 'lodash/flowRight'
import uniq from 'lodash/uniq'
import {route} from 'router'

import './index.scss'

const models = [
  {model: 'G1N-U', deviceType: 100},
  {model: 'G1N', deviceType: 7},
  {model: 'S1-U', deviceType: 1},
  {model: 'G1N-B1', deviceType: 99}
]

const GET_DEVICES = gql`
  query GET_DEVICES {
    devices {
      id
      po
    }
  }
`

const ADD_PO = gql`
  mutation ADD_PO($po: Int, $sku: String, $manufacturerCode: String!, $items: [POItem]!) {
    addPO(po: $po, sku: $sku, manufacturerCode: $manufacturerCode, items: $items) {
      devices {
        id
      }
    }
  }
`

class NewPO extends Component {
  state = {
    manufacturer: 'sf',
    po: null,
    quantities: Object.keys(models).map(() => 0),
    sku: 'test1'
  }
  createPO = () => {
    this.props.addPO({
      variables: {
        po: this.state.po,
        sku: this.state.sku,
        manufacturerCode: this.state.manufacturer,
        items: models.map((m, i) => ({
          model: m.model,
          deviceType: m.deviceType,
          quantity: this.state.quantities[i]
        }))
      }
    })
    route('/logistics')
  }
  render() {
    const pos = uniq((this.props.data.devices || []).map(d => d.po))
    return (
      <div className="new-po">
        <div className="card">
          <div className="header">New P.O.</div>
          <div className="body">
            <InputRow>
              <Input
                type="select"
                label="Manufacturer"
                value={this.state.manufacturer}
                onChange={e => this.setState({manufacturer: e.target.value})}
                options={Object.keys(manufacturers).map(k => ({
                  label: manufacturers[k],
                  value: k
                }))}
              />
              <Input
                type="select"
                label={'SKU'}
                value={this.state.sku}
                options={skus}
                onChange={e => this.setState({sku: e.target.value})}
              />
              <Input
                type="number"
                label={
                  !this.state.po || !pos.indexOf(this.state.po) === -1
                    ? 'P.O. Number'
                    : 'P.O. Number (already exists)'
                }
                value={this.state.po}
                onChange={e => this.setState({po: e.target.value})}
              />
            </InputRow>
            <div className="row row-header">
              <div className="model">Model</div>
              <div className="quantity">Quantity</div>
            </div>
            {models.map((m, i) => (
              <div key={i} className="row">
                <div className="model">{m.model}</div>
                <input
                  type="number"
                  className="quantity"
                  onChange={e =>
                    this.setState(
                      update(this.state, {
                        quantities: {[i]: {$set: parseFloat(e.target.value)}}
                      })
                    )
                  }
                  value={this.state.quantities[i]}
                />
              </div>
            ))}
            <div className="footer">
              <button className="primary" onClick={this.createPO}>
                Create P.O.
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  graphql(GET_DEVICES),
  graphql(ADD_PO, {name: 'addPO'})
)(NewPO)
