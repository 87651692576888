import React from 'react'
import TagIcon from 'Components/TagIcon'
import TagClass from 'utils/Tag'
import {linkHandler} from 'router'
import ago from 'utils/ago'

import './TagCard.scss'

export default ({device, tag, horizontal, shrink, label}) => {
  const deviceID = device.id
  const iconFluid = device.iconFluid
  const defaultTag = device.defaultTag
  const time = device.lastTimeMessageReceived
  const value = device.latestReading

  if(device?.displayUnits && tag?.tag === defaultTag) {tag['displayUnits'] = device.displayUnits}
  const Tag = new TagClass(tag)
  let className = 'tag-card '
  if (Tag.alarms.flag === 'norm') className += 'alarm-norm '
  else if (
    Tag.alarms.flag === 'low' ||
    Tag.alarms.flag === 'high' ||
    Tag.alarms.flag === 'low_warning' ||
    Tag.alarms.flag === 'high_warning' ||
    Tag.alarms.flag === 'critical_high' ||
    Tag.alarms.flag === 'critical_low'
  )
    className += 'alarm-low-high '
  if (horizontal) className += 'horizontal '
  if (shrink) className += 'shrink '
  return (
    <div
      className={className}
      onClick={linkHandler(`/devices/${deviceID}/tags/${defaultTag}`)}
    >
      <div className="gauge">
        {!!label && <div className="label">{Tag.label}</div>}
        <TagIcon width="70%" tag={Tag} fluid={iconFluid} value={value} defaultTag={defaultTag} />
      </div>
      <div className="readout">
        {(Tag?.tag === 'flw'  || (Tag?.tag === 'puls' && Tag?.displayUnits === 'MCF/D')) ? (<div className="value">MCF/D{Tag?.alarms?.rules?.filter((i) => i.sent !== 0).length > 0 && (<i className='fa fa-warning' style={{color: 'red'}}/>)}</div>)
        : (<div className="value">{Tag.display.render(value)} {Tag?.alarms?.rules?.filter((i) => i.sent !== 0).length > 0 && (<i className='fa fa-warning' style={{color: 'red'}}/>)}</div>)}
        <div className="time">{ago(time)}</div>
      </div>
    </div>
  )
}