import React from 'react'
import {ReactComponent as NoAlarms} from 'assets/noAlarms.svg'
import DeviceCard from 'Components/DeviceCard'
import {graphql} from 'react-apollo'
import { gql } from 'graphql.macro'
import Loading from 'Components/Loading'
import * as compose from 'lodash/flowRight'

import './Alarms.scss'

export const GET_DEVICES_ALARMS = gql`
  query GET_DEVICES_ALARMS($query: String) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    devicesRe(query: $query) {
      id
      name
      latestReading
      lastTimeMessageReceived
      displayUnits
      defaultTag
      iconFluid
      tags {
        id
        deviceID
        tag
        alarms {
          criticalLow
          criticalHigh
          lowWarning
          highWarning
          flag
        }
      }
    }
    activeAlarmRules {
      id
      name
      latestReading
      lastTimeMessageReceived
      displayUnits
      defaultTag
      iconFluid
      tags {
        id
        deviceID
        tag
        alarms {
          criticalLow
          criticalHigh
          lowWarning
          highWarning
          flag
        }
      }
    }
  }
`

const Alarms = ({data}) => {
  if (data.error) return <div>error</div>
  if (data.loading) return <Loading />
  // if (!!data && data.me.isSuperUser) {
  //   route('/alerts')
  // } 
  // tag !== '_v' && tag !== '_t' && 
  const get = (data.devicesRe || [])

  const raw = [...get, ...data?.activeAlarmRules || []]
  const alarms = Array.from(new Map(raw.map(i => [i.id, i])).values())


    return (
      <>
      {!data.me.isSuperUser && (alarms.length === 0) && (
      <div className="no-alarms">
        <NoAlarms />
        <h1>No alarms</h1>
      </div>
      )}
      {!data.me.isSuperUser && (alarms.length !== 0) && (
      <div className=" device-card-container">
        <div className=" device-card-row">
          <div className="title">Alarms</div>
          {alarms.map((d) => (
            <DeviceCard device={d} key={d.id} />
          ))}
        </div>
      </div>)}
      </>
    )
}

export default compose(
  graphql(GET_DEVICES_ALARMS, {
    options: () => ({
      variables: {query: 'alarm'}
    })
  })
)(Alarms)
