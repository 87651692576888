import './logistics.scss'

//intentionally ordered for menus
export const statuses = [
  {code: 1, short: 'ID Reserved', protected: true},
  {code: 2, short: 'PO Created', protected: true},
  {code: 3, short: 'Tested Ok', protected: true},
  {code: 4, short: 'Shipped to SF', protected: false},
  {code: 5, short: 'Received by SF', protected: false},
  {code: 6, short: 'Shipped to Dist', protected: false},
  {code: 7, short: 'Shipped to Cust', protected: false},
  {code: 8, short: 'Received by Dist', protected: false},
  {code: 9, short: 'Sold to Cust', protected: false},
  {code: 10, short: 'Warrenty Replacement', protected: false},
  {code: 11, short: 'Installed', protected: false},
  {code: 12, short: 'Device Problem', protected: false},
  {code: 17, short: 'Problem Solved', protected: false},
  {code: 14, short: 'Needs replacement', protected: false},
  {code: 15, short: 'Replacement Sent', protected: false},
  {code: 16, short: 'Device Deactivated', protected: false}
]

//find code by text
export const statusCode = statuses.reduce(
  (obj, s) => ({...obj, [s.short.toUpperCase().replace(/ /g, '_')]: s.code}),
  {}
)
export const status = statuses.reduce(
  (obj, s) => ({...obj, [s.code]: s.short}),
  {}
)
export const manufacturers = {
  sf: 'Sensorfield',
  via: 'VIA'
}

export const skus = ['SF-TR-XLR-S-V2-Cellular-USA-M8M12',
  'SF-TR-XL-C1-V2-Cellular-USA-M12M12',
  'SF-TR-XL-S-V2-Cellular-USA-M12M12',
  'SF-TR-XL-S-V2-Cellular-USA-M8M12',
  'SF-TR-XL-S-V2-Cellular-INT-M8M12']

export const renderStatus = code =>
  statuses[code] ? statuses[code].short : null
