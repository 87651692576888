import React, {Component} from "react";
import "./InputDropdown.scss";

class InputDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        status: "",  
        contentLevelOil: "",  
        interfaceLevelWater: "",
        pressure: "",
        flowRate: "",
        differentialMeter:"",
      },
      selectedOption: "",
      newOptionValue: "",
      showModal: false,
    }
  }

  componentDidMount() {
    this.parseDescription();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.description !== this.props.description) {
      this.parseDescription();
    }
  }

  parseDescription = () => {
    let { description } = this.props;
    if (description === null) {
      description = "";
    }
    const options = { status: "", contentLevelOil: "", interfaceLevelWater: "", pressure: "", flowRate: "",differentialMeter: "" };
    description.split(",").forEach((item) => {
      const [key, value] = item.split(":");
      if(options.hasOwnProperty(key.trim())) {
        options[key.trim()] = value.trim();
      }
    });

    this.setState({ options });
  };

  handleChange = (event) => {
    if (this.props.static) {
      alert("Click the 'Edit Device' button to modify the values. ");
      return
    }

    const selectedOption = event.target.value;
    this.setState({
      selectedOption,
      newOptionValue: this.state.options[selectedOption],
      showModal: true,
    });
  };

  handleModalClose = () => {
    this.setState({ showModal: false});
  };

  handleSave = () => {
    const { selectedOption, newOptionValue, options } = this.state;
    let updatedOptions = {...options};

    if (!newOptionValue) {
      delete updatedOptions[selectedOption];
    } else {
      updatedOptions[selectedOption] = newOptionValue;
    }

    const updatedDescription = Object.entries(updatedOptions)
    .map(([key, value]) => `${key}:${value}`)
    .join(",");

    this.props.updateDescription(updatedDescription)

    this.setState({
      options: updatedOptions,
      showModal: false,
    });
  };

  render() {
    const { options, selectedOption, newOptionValue, showModal } = this.state;
    return(
      <div className="dropdown-container-custom">
        <select value ={selectedOption} onChange={this.handleChange} className={this.props.static? "disabled-dropdown" : ""}>
          {/* <option value="">--Select--</option> */}
          {Object.entries(options).map(([key, value]) => {
            return(
              <option key={key} value={key}>
                {key === "status" ? "Run Status" : 
                 key === "contentLevelOil" ? "Content Level - Oil" : 
                 key === "interfaceLevelWater" ? "Interface Level - Water":
                 key === "pressure" ? "Pressure" :
                 key === "flowRate" ? "Flow Rate - Linear Meter" :
                 key === "differentialMeter" ? "EFM - Differential Meter" : key}{value ? "|": ""} {value}
              </option>
            )
          })}
        </select>

        {showModal && (
          <div className="modal-overlay">
            <div className="modal">
              <h3>Edit Option Value</h3>
              <div className="modal-body">
                <input 
                  type="text"
                  value={newOptionValue}
                  onChange={(e) =>
                    this.setState({ newOptionValue: e.target.value })
                  }
                />
              </div>
                <p className="reminder">
                Please ensure that you click "Save Changes" to store your updates. Unsaved changes will be lost if you leave or reload the page.
                </p>
                <div className="modal-footer">
                  <button className="close-btn" onClick={this.handleModalClose}>
                    Close
                  </button>
                  <button className="save-btn" onClick={this.handleSave}>
                    Save
                  </button>
                </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default InputDropdown;