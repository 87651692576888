import React, {useState} from 'react'
import {ReactComponent as NoAlarms} from 'assets/noAlarms.svg'
import {GET_DEVICES_ALARMS} from './AlarmsPage'
import Loading from 'Components/Loading'
import DeviceCardSmall from 'Components/DeviceCardSmall'
import {Query, graphql} from 'react-apollo'
import './LandingPage.scss'
import List from './List'
import {connect} from 'react-redux'
import * as compose from 'lodash/flowRight'
import {
    setDirectoryPath,
    setDirectoryCompany,
    toggleIncludeInactive,
    toggleIncludeOOS,
    setViewType,
    toggleIncludeVIA
  } from 'store/actions'
import { gql } from 'graphql.macro'
import Modal from 'Components/Modal'
import NotificationCard from './NotificationCard'
import formatEpochTime from 'utils/formatEpochTime'
import Tooltip from 'Components/Tooltip'

const GET_ME_AND_DATA = gql`
  query GET_ME_AND_DATA {
    me {
      id
      firstName
      lastName
      email
      phoneNumber
      countryCode
      isSuperUser
      isManager
      isDemo
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    landingPage {
      device
      tardy
      health
      alarm
    }
  }
`

const GET_TARDY_DEVICES = gql `
  query GET_TARDY_DEVICES($query: String, $previousDays: Int!, $recentDays: Int!, $limit: Int!) {
    devicesRe(query: $query, previousDays: $previousDays, recentDays: $recentDays, limit: $limit) {
      id
      userAssignedID
      name
      path
      lastTimeMessageReceived
      companyID
      company {
        name
      }
    }
  }
`

const GET_VOLTAGE_CHECK = gql `
  query GET_VOLTAGE_CHECK($query: String) {
    devicesRe(query: $query) {
      id
      userAssignedID
      name
      path
      lastTimeMessageReceived
      batteryLevel
    }
  }
`
const GET_NOTIFICATION = gql `
  query GET_NOTIFICATION  {
    notice  {
      title
      notice_content
      notice_type
      created_at
    }
  }
`

const getColor = (value) => {
  if (value >= 80) return "green" // High percentage
  if (value >= 50) return "yellow" // Medium percentage
  return "red" // Low percentage
}

const LandingPage = ({overview, tardy, volt, notification}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedNotification, setSelectedNotification] = useState(null)
  const currentTimeUnix = Math.floor(new Date().getTime() / 1000)

  if (overview?.loading) return <Loading />
  if (tardy?.loading) return <Loading />
  if (volt?.loading) return <Loading />

  const isSuperUser = overview?.me?.isSuperUser === true
  const isManager = overview?.me?.isManager === true

  const filterCondition = {
    company: 10300,
    time: currentTimeUnix - (60 * 60 * 60)
  }

  const alerts = tardy?.devicesRe
    .filter((d) => !(d.companyID === filterCondition.company && d.lastTimeMessageReceived >= filterCondition.time))

  const voltage = volt?.devicesRe
  const notifications = notification?.notice

  const activeCount = overview?.landingPage?.device || 0
  const alarmCount = overview?.landingPage?.alarm || 0
  const healthCount = overview?.landingPage?.health || 0
  const tardyCount = overview?.landingPage?.tardy || 0
  
  const percent = ((100-(healthCount/activeCount)*100).toFixed(2))

  const openModal = (notification) => {
    setSelectedNotification(notification);
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedNotification(null);
  }

  return (
    <>
      <div className="directory-body">
        <div className="grid landing">
          <div className="box banner">
            <div className="content">
              <div className="sep">
                <h2>Health<Tooltip>We consider active devices that are still sending sensor data in schedule as healthy. This is a test indicator and may change in future updates.</Tooltip></h2>
                <span style={{ color: getColor(percent)}}>{percent}%</span>
              </div>
              <div className="sep">
                <h2>Active Devices</h2>
                <span>{activeCount}</span>
              </div>
              <div className="sep">
                <h2>Tardy Devices</h2>
                <span>{tardyCount}</span>
              </div>
              <div className="sep">
                <h2>Active Alarms</h2>
                <span>{alarmCount > 0 && <i className="fa fa-triangle-exclamation" style={{color: "red"}}/>} {alarmCount}</span>
              </div>
            </div>
          </div>
          {!isSuperUser && (
          <div className="box alarm">
            <div className="small-content-header">
              <h2>Alarms</h2>
              <a href="/#/alarms">
                Expand
              </a>
          </div>
            <Query query={GET_DEVICES_ALARMS} variables={{query: 'alarm'}}>
                {({error, loading, data}) => {
                  if (error) return 'error'
                  if (loading) return <Loading />

                  // tag !== '_v' && tag !== '_t' &&
                  const get = (data.devicesRe || [])

                  const raw = [...get, ...data?.activeAlarmRules || []]
                  const alarms = Array.from(new Map(raw.map(i => [i.id, i])).values())


                  if (alarms?.length === 0)
                    return (
                      <div className="no-alarms">
                        <NoAlarms />
                        <h1>No alarms</h1>
                      </div>
                    )
                  else
                    return (
                      <>
                        {!data.me.isSuperUser && (
                          <div className=" device-card-container-small">
                            {alarms.map((d) => (
                              <DeviceCardSmall device={d} key={d.id} />
                            ))}
                          </div>
                        )}
                      </>
                    )
                }}
            </Query>
          </div>)}
          <div className={!isSuperUser ? "box alert" : 'box alert2'}>
              <div className="small-content-header">
                <div>
                  <h2><i className="fa fa-clock"/> Tardy Devices<Tooltip>Click see all to change time range.</Tooltip></h2>
                </div>
                {(isSuperUser || isManager) && <a href="/#/alerts">
                  See All
                </a>}
              </div>
              <div className="small-content">
                {alerts?.length === 0 ? (
                  <div className="no-alarms">
                    <p className="no-notifications">No Tardy Devices</p>
                  </div>
                ) : (
                  <List
                    devices={alerts} type={'alertCompact'}
                  />
                )}
              </div>
          </div>
          <div className="box device">
            <div className="small-content-header">
              <h2>Battery Voltage Warning<Tooltip>Low voltage warning - 3.60V for solar panel batteries, 4.70V for battery packs. This is a warning only (your device may be fine).</Tooltip></h2>
            </div>
            {voltage?.length > 0 ? (
            <div className="small-content">
                <List
                    devices={voltage} type={'batteryCompact'}
                  />
            </div>) :
            <p className="no-notifications">No devices with battery voltage warnings</p>
            }
          </div>
          <div className="box alert">
            <div className="small-content-header">
              <div>
                <h2>Notifications</h2>
              </div>
            </div>
            <div className="small-content">
              <div className="card-body">
                {notifications?.length > 0 ? (
                  <>
                  <ul className="notification-list">
                    {notifications.map((notification, index) => (
                        <NotificationCard
                          key={index}
                          notification={notification}
                          openModal={openModal}

                        />
                    ))}
                  </ul>
                  {isModalOpen && (
                    <Modal onClose={closeModal}>
                      <Modal.Title>Notification Details</Modal.Title>
                      <Modal.Body>
                        <strong>Category:</strong> {selectedNotification?.notice_type} <br />
                        <strong>Title:</strong> {selectedNotification?.title} <br />
                        <strong>Date:</strong> {formatEpochTime(selectedNotification?.created_at)} <br />
                        <strong>Message:</strong> {selectedNotification?.notice_content} <br />
                      </Modal.Body>
                      <Modal.Footer>
                        <span className="button primary" onClick={closeModal}>
                          Close
                        </span>
                      </Modal.Footer>
                    </Modal>
                  )}
                  </>
                ) : (
                  <p className="no-notifications">No notifications available</p>
                )}
              </div>
            </div>
          </div>
      </div>
    </div>
    </>
  )
}

const mapStateToProps = (state) => ({
    path: state.directory.path,
    companyID: state.directory.companyID,
    includeInactive: state.directory.includeInactive,
    includeOOS: state.directory.includeOOS,
    includeVIA: state.directory.includeVIA,
    viewType: state.directory.viewType
  })
  const mapDispatchToProps = (dispatch) => ({
    setDirectoryPath: (path) => dispatch(setDirectoryPath(path)),
    setDirectoryCompany: (id) => {
      dispatch(setDirectoryPath(''))
      dispatch(setDirectoryCompany(id))
    },
    toggleIncludeInactive: () => dispatch(toggleIncludeInactive()),
    toggleIncludeOOS: () => dispatch(toggleIncludeOOS()),
    toggleIncludeVIA: () => dispatch(toggleIncludeVIA()),
    setViewType: (type) => dispatch(setViewType(type))
  })
  
  export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    graphql(GET_ME_AND_DATA, {
      name: 'overview'
    }),
    graphql(GET_TARDY_DEVICES, {
      name: 'tardy',
      options: () => ({
        variables: {query: 'alert', recentDays: 24, previousDays: 240, limit: 10},
      })
    }),
    graphql(GET_VOLTAGE_CHECK, {
      name: 'volt',
      options: () => ({
        variables: {query: 'voltage'}
      })
    }),
    graphql(GET_NOTIFICATION, {
      name: 'notification'
    })
  )(LandingPage)