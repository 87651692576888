import React, { useState } from 'react'
import {Query} from 'react-apollo'
import {gql} from 'graphql.macro'
import Loading from 'Components/Loading'
import {route} from 'router'

import './Activity.scss'

const GET_USER = gql`
  query GET_USER ($startDate: String!) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    userActivity(startDate: $startDate) {
        mobile {
          user
          company
          month
          day
          count
        }
        desktop {
          user
          company
          month
          day
          count
        }
        api {
          user
          company
          month
          day
          count
        }
      }
  }
`

const Activity = () => {
  const [sortBy, SetSortBy] = useState('name')
  const [sortDirection, SetSortDirection] = useState(true)

  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0])


  const getDateBetween = (startDate, endDate) => {
    const dates = [];

    let currentDate = new Date(startDate);
    endDate = new Date(endDate);

    currentDate.setHours(0,0,0,0);
    endDate.setHours(0,0,0,0);

    currentDate.setDate(currentDate.getDate() + 1)

    endDate.setDate(endDate.getDate() + 1)
   

    while (currentDate <= endDate){
      dates.push(currentDate.toLocaleDateString('en-US', {month: '2-digit', day: '2-digit'}));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  function addDays (date, days){
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString().split('T')[0];
  }

  const dates = getDateBetween(startDate, addDays(startDate, 7));

  // for (let i = 6; i >= 0; i--) {
  //   const date = new Date()
  //   date.setDate(date.getDate() - i)
  //   dates.push(date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }))
  // }

  const formatTime = (count) => {
    const hours = Math.floor(count / 60);
    const minutes = count % 60;
  
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}`;
  }

  // Need to change date format to match exactly for the dates list. Add a 0 for 1 digit because the list is 2-digits
  const formatDate = (names, mobile, desktop) => {

    const mobileData = []
    const desktopData = []
    // const apiData = {}
  
    for (const i of names){
      mobileData.push({user: i})
      desktopData.push({user: i})
    }


    for(const j of mobile){
      let date
      if (j.month < 10) {
        if (j.day < 10) {
          date = `0${j.month}/0${j.day}`
        } else {
          date = `0${j.month}/${j.day}`
        }
      } else if (j.month >= 10) {
          if (j.day < 10) {
            date = `${j.month}/0${j.day}`
          } else {
            date = `${j.month}/${j.day}`
          }
      }
      mobileData.find(obj => obj.user === j.user)['company'] = j.company
      mobileData.find(obj => obj.user === j.user)[date] = j.count

    }
  
    for(const k of desktop){
      let date
      if (k.month < 10) {
        if (k.day < 10) {
          date = `0${k.month}/0${k.day}`
        } else {
          date = `0${k.month}/${k.day}`
        }
      } else if (k.month >= 10) {
          if (k.day < 10) {
            date = `${k.month}/0${k.day}`
          } else {
            date = `${k.month}/${k.day}`
          }
      }
      desktopData.find(obj => obj.user === k.user)['company'] = k.company
      desktopData.find(obj => obj.user === k.user)[date] = k.count
      }

    return { mobile: mobileData, desktop: desktopData}
  }
  // const sortFunction = () => {
  //   switch (sortBy) {
  //     case 'name':
  //       return (a, b) =>
  //         (reverse ? 1 : -1) *
  //         ((a.path || 'zzzzz') + (a.name || 'zzzzz')).localeCompare(
  //           (b.path || 'zzzzz') + (b.name || 'zzzzz')
  //         )
  //     case 'company':
  //       return (a, b) =>
  //         (reverse ? 1 : -1) *
  //         (a.companyName || 'zzzzz').localeCompare((b.companyName || 'zzzzz'))
  // }

  const sortIndicator = (key) =>
    sortBy === key ? (
    !!sortDirection ? (
      <i className="fa fa-caret-up" />
    ) : (
      <i className="fa fa-caret-down" />
    )
  ) : null

  const sortChange = (key) => (e) => {
    e.preventDefault()
    if (sortBy === key){
      SetSortDirection(!sortDirection)
    }
    else{
      SetSortBy(key)
      SetSortDirection(true)
    }
  
  }

  return (
    <Query query={GET_USER} variables={{startDate}}>
      {({error, loading, data}) => {
        if (error) return 'error'
        if (loading) return <Loading />
        if (!!data && !data.me.isSuperUser) {
          route('/alarms')
        } 

        const names = []

        // Take out the unique names inside the data object as there are duplicates
        for(const i in data.userActivity.mobile){
          if(!names.includes(data.userActivity.mobile[i].user)){
            if(!!data.userActivity.mobile[i]) {
              names.push(data.userActivity.mobile[i].user)
            }
          }
        }

        for(const i in data.userActivity.desktop){
          if(!names.includes(data.userActivity.desktop[i].user)){
            if(!!data.userActivity.desktop[i]) {
              names.push(data.userActivity.desktop[i].user)
            }
          }
        }

        // for(const i in data.userActivity.api){
        //   if(!names.includes(data.userActivity.api[i].user)){
        //     names.push(data.userActivity.api[i].user)
        //   }
        // }

          // names.sort(
          //   (a, b) => ((!!sortDirection) ? 
          //   (a || 'zzzzz').localeCompare((b || 'zzzzz')) 
          //   : (b || 'zzzzz').localeCompare((a || 'zzzzz'))))

          
        const newData = formatDate(names, data.userActivity.mobile, data.userActivity.desktop)

            names.sort(
              (a,b) => {
                switch (sortBy){
                  case 'name':
                    return !!sortDirection ?
                    (a || 'zzzzz').localeCompare((b || 'zzzzz')) :
                    (b || 'zzzzz').localeCompare((a || 'zzzzz'))
                case 'company':
                  const companyA = newData.mobile.find(obj=> obj.user === a)?.company||
                                   newData.desktop.find(obj => obj.user === a)?.company || 'zzzzz'
                  const companyB = newData.mobile.find(obj=> obj.user === b)?.company||
                                   newData.desktop.find(obj => obj.user === b)?.company || 'zzzzz'
                  return !!sortDirection ?
                    companyA.localeCompare(companyB)
                    : companyB.localeCompare(companyA)
                  default:
                    return 0

                }
              }
            )

        // switch (sortBy) {
        //   case 'name':
        //     names.sort(
        //     (a, b) => ((!!sortDirection) ? 
        //     (a || 'zzzzz').localeCompare((b || 'zzzzz')) 
        //     : (b || 'zzzzz').localeCompare((a || 'zzzzz'))))

        //     console.log('sorting name')
        //     break
        //   case 'company':
        //     names.sort(
        //       (a, b) => ((!!sortDirection) ? 
        //       (companies.indexOf(a) - companies.indexOf(b)) 
        //       : (companies.indexOf(b) - companies.indexOf(a))))

        //     console.log('sorting company')
        //     break
        //   default:
        //     return
        // }


        return (
            <>{!!data.me.isSuperUser && (
            <div className='directory-body'>
              <div className='directory-top-bar'>
                <p className='title2344'>ACTIVITY VIEW</p>
                <div style={{position: 'absolute', left: '55%', display: 'flex', alignItems:'center'}}>
                <label htmlFor="startDate" style={{fontSize:'16px', fontWeight:'normal'}}>Search From Date:</label>
                <input type='date' id="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} style={{marginLeft: '5px', borderRadius: '4px', border: '1px solid #ccc', fontSize: '16px', marginRight: '20px'}}/>
                <div style={{ width: '20px', height: '20px', backgroundColor: 'blue'}}></div>
                <span>Mobile</span>
                <div style={{ width: '20px', height: '20px', backgroundColor: 'green', marginLeft: '5%'}}></div>
                <span>Desktop</span>
                </div>
              </div>
              <div className='top-class'>
              <table>
            <thead>
              <tr style={{ position: 'sticky', top: '0', zIndex: '100', backgroundColor: 'grey'}}>
                <th style={{textAlign: 'center', color: 'white', cursor: 'pointer', width: '150px'}} onClick={sortChange('name')}>Name {sortIndicator('name')}</th>
                <th style={{textAlign: 'center', color: 'white', cursor: 'pointer', width: '150px'}} onClick={sortChange('company')}>Company {sortIndicator('company')}</th>
                {dates.map(date => (
                  <th style={{textAlign: 'center', color: 'white'}} key={date} colSpan="2">{date}</th>
                ))}
              </tr>
              <tr> 
                <th></th>
                <th></th>
                {dates.map(date => (
                  <React.Fragment key={date}>
                    <th className='sub-column-header'>Mobile</th>
                    <th className='sub-column-header2'>Desktop</th>
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {names.map(name => (
                <tr key={name}>
                  <td style={{ justifyContent: 'center', textAlign: 'center'}}>{name}</td>
                  <td style={{ borderLeft: '1px inset', justifyContent: 'center', textAlign: 'center'}}>{newData.mobile.find(obj => obj.user === name)['company'] || newData.desktop.find(obj => obj.user === name)['company'] || 'N/A'}</td>
                  {dates.map(date => (
                    <React.Fragment key={date}>
                      <td className='sub-column'>
                      <div
                      className="bar mobile-bar"
                      style={{ width: `${(newData.mobile.find(obj => obj.user === name)[date] / 100) * 100}%`, maxWidth: '85%' }}
                    />
                      <div className='cell-content'>{newData.mobile.find(obj => obj.user === name)[date]  ? formatTime(newData.mobile.find(obj => obj.user === name)[date] ) : '00:00'}</div>
                      </td>
                      <td className='sub-column2'>
                      <div
                      className="bar desktop-bar"
                      style={{width: `${(newData.desktop.find(obj => obj.user === name)[date]  / 100) * 100}%`, maxWidth: '85%' }}
                    />
                      <div className='cell-content'>{newData.desktop.find(obj => obj.user === name)[date]  ? formatTime(newData.desktop.find(obj => obj.user === name)[date] ) : '00:00'}</div>
                      </td>
                    </React.Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          </div></div>)}
          </>
          )
      }}
    </Query>
  )
}

export default Activity
